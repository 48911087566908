import React, {useEffect, useState} from "react";
import {useWindowSize} from "@tools/hooks";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import styled from "styled-components";
import {TypographyUI} from "@ui";

import stickyImage1 from "@images/telegram/StickyStepsSectionImage1.png";
import stickyImage2 from "@images/telegram/StickyStepsSectionImage2.png";
import stickyImage3 from "@images/telegram/StickyStepsSectionImage3.png";
import stickyImage4 from "@images/telegram/StickyStepsSectionImage4.png";

export const TelegramStickyStepsSection = () => {

    const {width} = useWindowSize();

    const stickyItems = React.useRef(null);

    const [image, setImage] = useState(stickyImage1);

    useEffect(() => {
        const listenScrollEvent = () => {
            const elOffset = stickyItems.current.offsetTop - ((window.innerHeight / 2 ) - 219) - 100;
            const stickyImgOffset1 = elOffset + 438;
            const stickyImgOffset2 = stickyImgOffset1 + 438;
            const stickyImgOffset3 = stickyImgOffset2 + 438;
            const stickyImgOffset4 = stickyImgOffset3 + 438;
            if (window.scrollY < stickyImgOffset1){
                setImage(stickyImage1)
            } else if (window.scrollY >= stickyImgOffset1 && window.scrollY < stickyImgOffset2) {
                setImage(stickyImage2);
            } else if (window.scrollY >= stickyImgOffset2 && window.scrollY < stickyImgOffset3) {
                setImage(stickyImage3);
            } else if (window.scrollY >= stickyImgOffset3){
                setImage(stickyImage4);
            } else {
                setImage(stickyImage1);
            }


        }
        window.addEventListener('scroll', listenScrollEvent);
        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        };
    }, []);


    return (
        <StickyStepsSection>
            <StickyStepsSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Как работает Телеграм-бот для приема денег
            </StickyStepsSectionTitle>

            <StickyStepsSectionItems ref={stickyItems}>
                <StickyStepsSectionItemsContent>
                    <StickyStepsSectionItem>

                        <StickyStepsSectionItemImage>
                            <img src={stickyImage1} alt={''}/>
                        </StickyStepsSectionItemImage>

                        <StickyStepsSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                            Шаг 1
                        </StickyStepsSectionItemTitle>
                        <StickyStepsSectionItemDescription>
                            Покупатель заходит в ваш Телеграм-канал с настроенным чат-ботом и отправляет сообщение с заданной командой, например: «Хочу оплатить консультацию»
                        </StickyStepsSectionItemDescription>
                    </StickyStepsSectionItem>

                    <StickyStepsSectionItem>

                        <StickyStepsSectionItemImage>
                            <img src={stickyImage2} alt={''}/>
                        </StickyStepsSectionItemImage>

                        <StickyStepsSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                            Шаг 2
                        </StickyStepsSectionItemTitle>
                        <StickyStepsSectionItemDescription>
                            Бот отправляет покупателю счет с нужной суммой
                        </StickyStepsSectionItemDescription>
                    </StickyStepsSectionItem>

                    <StickyStepsSectionItem>

                        <StickyStepsSectionItemImage>
                            <img src={stickyImage3} alt={''}/>
                        </StickyStepsSectionItemImage>

                        <StickyStepsSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                            Шаг 3
                        </StickyStepsSectionItemTitle>
                        <StickyStepsSectionItemDescription>
                            Покупатель попадает на форму оплаты прямо в Телеграм — счет можно оплатить банковской картой или через СБП
                        </StickyStepsSectionItemDescription>
                    </StickyStepsSectionItem>

                    <StickyStepsSectionItem>

                        <StickyStepsSectionItemImage>
                            <img src={stickyImage4} alt={''}/>
                        </StickyStepsSectionItemImage>

                        <StickyStepsSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                            Шаг 4
                        </StickyStepsSectionItemTitle>
                        <StickyStepsSectionItemDescription>
                            Бот отправляет сообщение об успешной оплате и передает информацию о продаже в личный кабинет Кошелька
                        </StickyStepsSectionItemDescription>
                    </StickyStepsSectionItem>
                </StickyStepsSectionItemsContent>
                <StickyStepsSectionItemsImage>
                    <StickyStepsSectionItemsImageInner>
                        <img src={image} alt={'step'}/>
                    </StickyStepsSectionItemsImageInner>
                </StickyStepsSectionItemsImage>
            </StickyStepsSectionItems>
        </StickyStepsSection>
    )

}

const StickyStepsSection = styled.div`
    margin: 120px auto 80px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px auto 40px;
    }
`;

const StickyStepsSectionTitle = styled(TypographyUI.H2)`
    width: 820px;
    margin: 0 auto 50px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const StickyStepsSectionItems = styled.div`
    margin-top: 100px;
    display: flex;
    position: relative;
    justify-content: space-between;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 40px;
    }
`;

const StickyStepsSectionItem = styled.div`
    width: 392px;
    height: 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        height: auto;
    }
`;

const StickyStepsSectionItemsContent = styled.div`

`;

const StickyStepsSectionItemsImage = styled.div`
    position: relative;
    height: auto;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;

const StickyStepsSectionItemsImageInner = styled.div`
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
    top: calc(50vh - 219px);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 438px;
    overflow: hidden;
    border-radius: 20px;
`;

const StickyStepsSectionItemImage= styled.div`
    display: none;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: block;
        margin-bottom: 40px;
    }
`;

const StickyStepsSectionItemTitle = styled(TypographyUI.H4Bold)`
    margin-bottom: 16px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 12px;
    }
`;

const StickyStepsSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;
