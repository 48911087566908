import {Accordion, AccordionItem as Item} from "@szhsin/react-accordion";
import React from "react";
import chevronDown from "@images/telegram/chevron-down.svg";
import * as styles from "./style.module.scss";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

const AccordionItem = ({header, ...rest}) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <img className={styles.chevron} src={chevronDown} alt="Chevron Down"/>
            </>
        }
        className={styles.item}
        buttonProps={{
            className: ({isEnter}) =>
                `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
        }}
        contentProps={{className: styles.itemContent}}
        panelProps={{className: styles.itemPanel}}
    />
);


const items = [
    {
        header: "Что потребуется для начала работы с Кошельком",
        content: [
            "После регистрации в Кошельке физическому лицу без статуса самозанятого потребуется предоставить паспортные данные. Без проверки личности вы не сможете пользоваться приемом и выводом средств — это требование закона ст. 7 115-ФЗ.",
            "Если вы самозанятый, то помимо паспортных данных Кошелек попросит указать ИНН и стать партнером ООО «Самозанятые» в мобильном приложении «Мой налог». Так Кошелек сможет отслеживать ваш ежегодный лимит, создавать за вас чеки, отправлять информацию в ФНС и напоминать о дате уплаты налогов."
        ]
    },
    {
        header: "Могут ли физические лица использовать все способы приема платежей",
        content: [
            "Да, но с одним условием — физическое лицо принимает деньги в некоммерческих целях. Например, вы можете отправить другу ссылку с просьбой вернуть долг за поход в ресторан или создать копилку для общих целей. Принимать платежи за продажу товаров и услуг физическим лицам запрещено — получение систематического дохода без регистрации в ИФНС и уплаты налогов считается незаконной предпринимательской деятельностью."
        ]
    },
    {
        header: "Нужно ли дублировать продажи в приложении «Мой налог»",
        content: [
            "Нет, после синхронизации Кошелька с приложением «Мой налог» вам не нужно вручную создавать чеки и отправлять их покупателям — благодаря официальному статусу партнера ФНС Кошелек сам направит всю необходимую информацию в налоговую."
        ]
    },
    {
        header: "Нужен ли мне сайт для онлайн-продаж",
        content: [
            "Нет, сайт не потребуется, и в этом главное преимущество Кошелька. Вы можете отправить ссылку на платежную форму любым удобным способом: по СМС, имейлу, в мессенджере и социальных сетях или автоматизировать отправку ссылок через Телеграм-бот.",
            "А если вы хотите создать сайт с возможностью принимать оплату за товары и услуги, то вы можете подключить наш <a href=\"acquiring\">интернет-эквайринг</a> по АПИ или через специальный виджет."
        ]
    }
];

export const FaqTg = () => {
    return (
        <div className={styles.faq}>
            <TypographyUI.H2 className={styles.title} swichPoint={LAPTOP_MOBILE_POINTER}>Отвечаем на вопросы</TypographyUI.H2>
            <Accordion transition transitionTimeout={250}>
                {items.map((item, i) => (
                    <AccordionItem header={item.header} key={i}>
                        {item.content.map((contentString, i)=>
                            <div key={i} className={styles.itemPanelFaq} dangerouslySetInnerHTML={{__html: contentString}}></div>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
}
