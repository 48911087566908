import React from "react";
import {Helmet} from "react-helmet";
import {Layout} from "@components";
import {TopSection} from "@templates/telegram/TopSection";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER} from "@tools/constants";
import {QuizSection} from "@templates/main";
import {PayTelegramSection} from "@templates/telegram/PayTelegramSection";
import {FaqTg} from "@templates/telegram/faq-tg";
import {HowReceiveMoneySection} from "@templates/telegram/HowReceiveMoneySection";
import {TelegramInfoSection} from "@templates/telegram/TelegramInfoSection";
import {SimpleTelegramSection} from "@templates/telegram/SimpleTelegramSection";
import {TelegramStickyStepsSection} from "@templates/telegram/StickyStepsSection";


const TelegramPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Получение оплаты в Телеграм самозанятыми | Платежи через Телеграм для самозанятых
                </title>
                <meta
                    name="description"
                    content="Нужно получение оплаты в Телеграм самозанятыми? Наш сайт поможет вам с этим. Прием платежей в Telegram с кошельком самозанятых. Принимайте оплату от клиентов прямо в чате Телеграм"
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/telegram"
                />
            </Helmet>
            <Layout>

                <TopSection/>

                <PayTelegramSection/>

                <HowReceiveMoneySection/>

                <TelegramInfoSection/>

                <SimpleTelegramSection/>

                <TelegramStickyStepsSection/>

                <QuizSection/>

                <FaqTg/>

            </Layout>
        </>
    );
};

export default TelegramPage;

const MaxWidthBox = styled.div`

    margin: 0 auto;

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 0 32px;
    }
    @media (min-width: ${MOBILE_MINIMOBILE_POINTER + 1}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 0 20px;
    }
    @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
        max-width: 375px;
        padding: 0 16px;
    }

`;
