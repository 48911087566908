import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {AdaptiveLinkCt} from "@components";

export const TopSection = () => (
    <TopSectionContainer>
        <TopSectionContent>

            <TopSectionTitle
                swichPoint={LAPTOP_MOBILE_POINTER}
                variant="h1"
            >Настройте прием платежей через Телеграм
            </TopSectionTitle>
            <TopSectionDescription>
                Вместо долгих согласований, отправки реквизитов и платежных документов — оплата в один клик
            </TopSectionDescription>


            <AdaptiveLinkCt to="https://pro.selfwork.ru">
                <TopSectionBtn>
                    Попробовать
                </TopSectionBtn>
            </AdaptiveLinkCt>

        </TopSectionContent>

        <TopSectionImage>
            <StaticImage
                src="../../../images/telegram/TopSectionImage.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={504}
                height={332}
                layout="fixed"
                objectFit="contain"
                alt=""
            />
        </TopSectionImage>

    </TopSectionContainer>
);

const TopSectionContainer = styled.section`
    display: flex;
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 506px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
        height: 100%;
        margin-top: 37px;
    }
`;

const TopSectionContent = styled.div`
    width: 560px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const TopSectionTitle = styled(TypographyUI.H1)`
    span {
        color: var(--accent-main);
    }
    margin-bottom: 16px;
`;

const TopSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    margin-bottom: 48px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;

const TopSectionImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;

const TopSectionBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
